<template>
  <v-container>
    <v-row no-gutters>
      <v-col class="col-12 content-header align-left">
        <h1 class="content-title">
          <template v-if="$vuetify.breakpoint.smAndUp">
            Sign Up
          </template>
          <template v-else>
            <auth-toggle-header />
          </template>
        </h1>
      </v-col>
    </v-row>
    <v-row class="content-body justify-center align-center">
      <v-col class="col-12 py-0">
        <auth-form-layout
          submit-button-text="Sign Up"
          @submit-action="signUpAndRedirect(item)"
        >
          <v-col cols="12">
            <form-input
              v-model="item.firstName"
              label="First name *"
              :rules="rules.firstName"
              autocomplete="firstname"
            />
          </v-col>
          <v-col cols="12">
            <form-input
              v-model="item.lastName"
              label="Last name *"
              :rules="rules.lastName"
              autocomplete="lastname"
            />
          </v-col>
          <v-col cols="12">
            <form-input
              v-model="item.email"
              label="Email *"
              :rules="rules.email"
              autocomplete="email"
            />
          </v-col>
          <v-col
            cols="12"
            class="mb-4"
          >
            <form-input
              v-model="item.password"
              label="Password *"
              type="password"
              :rules="rules.password"
              autocomplete="new-password"
            />
          </v-col>

          <template v-slot:afterSubmitButton>
            <!-- <v-col class="col-12">
              <div class="login-form-divider text-center">
                <span>or</span>
              </div>
            </v-col>
            <v-col class="col-12 d-flex justify-center py-0">
              <auth-doxyme />
            </v-col> -->
            <v-col class="col-12">
              <div class="login-form-divider text-center">
                <span>or</span>
              </div>
            </v-col>
            <v-col class="col-12 d-flex justify-center py-0">
              <auth-google />
            </v-col>
          </template>
        </auth-form-layout>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="col-12 content-footer text-center">
        By signing up, you agree to our
        <router-link
          :to="{ name: 'termsOfService' }"
          class="text-decoration-underline"
        >
          Terms of Service
        </router-link>
        <div v-if="$vuetify.breakpoint.smAndUp">
          Already have an account?
          <router-link
            :to="{ name: 'signIn' }"
            class="text-decoration-underline"
          >
            Log in
          </router-link>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import AuthFormLayout from '@/components/auth/AuthFormLayout'
import AuthGoogle from '@/components/auth/AuthGoogle'
import AuthDoxyme from '@/components/auth/AuthDoxyme'
import AuthToggleHeader from '@/components/auth/AuthToggleHeader'
import FormInput from '@/components/form/FormInput'
import emailValidation from '@/helpers/emailValidation'

export default {
  name: 'SignUp',
  components: {
    AuthDoxyme,
    AuthFormLayout,
    AuthGoogle,
    AuthToggleHeader,
    FormInput
  },
  data() {
    return {
      item: {
        firstName: null,
        lastName: null,
        email: null,
        password: null
      },
      rules: {
        email: [v => !!v || 'Email is required', v => emailValidation(v)],
        password: [
          v => !!v || 'Password is required',
          v => (!!v && v.length >= 6) || 'Min 6 characters'
        ],
        firstName: [v => !!v || 'First name is required'],
        lastName: [v => !!v || 'Last name is required']
      }
    }
  },
  mounted() {
    const email = window.localStorage.getItem('emailForSignIn')
    const name = window.localStorage.getItem('nameForSignIn')
    console.log('email in signup:::', email, name)
    if (email) {
      this.item.email = email
      window.localStorage.removeItem('emailForSignIn')
    }
    if (name) {
      const splitName = name.split(' ')
      this.item.firstName = Array.isArray(splitName) ? splitName[0] : name
      this.item.lastName = Array.isArray(splitName) ? splitName[1] : ''
      window.localStorage.removeItem('nameForSignIn')
    }
  },
  methods: {
    ...mapActions('authentication', ['signUp']),
    async signUpAndRedirect(item) {
      // This method is never triggered for Google registration, the AuthGoogle component handles that
      if (await this.signUp(item)) {
        // Redirects to an email-confirm instructional page
        this.$gtag.event('conversion', { send_to: 'AW-10995571789/3DdFCNTIj4sYEM24jPso' })
        await this.$router.push({ name: 'signUpComplete', params: item })
      }
    }
  }
}
</script>

<style scoped>

.content-title {
  margin-top: 2rem;
}

</style>